import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import HomePage from "./pages/Home/HomePage";
import AboutPage from "./pages/About/AboutPage";
import MiniDrawer from "./component/Nav/MiniDrawer";
import { DrawerProvider } from "./contexts/DrawerContext";
import ProdutosPage from "./pages/Produtos/ProdutosPage";
import PedidosPage from "./pages/Pedidos/PedidosPage";
import LoginComponent from "./component/Login/Login";
import EstoquePage from "./pages/Estoque/EstoquePage";
import RecebimentoPage from "./pages/Recebimento/recebimentoPage";

const App: React.FC = () => {
  document.title = "RDF - Cliente";

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />

        <Route path="/login" element={<LoginComponent />} />

        <Route
          path="*"
          element={
            <DrawerProvider>
              <MiniDrawer />
              <Routes>
                <Route path="/home" element={<HomePage />} />
                <Route path="/about" element={<AboutPage />} />
                <Route path="/produtos" element={<ProdutosPage />} />
                <Route path="/pedidos" element={<PedidosPage />} />
                <Route path="/estoque" element={<EstoquePage />} />
                <Route path="/recebimento" element={<RecebimentoPage />} />
              </Routes>
            </DrawerProvider>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
