import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Fab,
  Snackbar,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Card,
  CardContent,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import {
  Plus,
  Calendar,
  FileText,
  Truck,
  Package,
  List as ListIcon,
  X,
  Eye,
  Trash2,
  Box,
} from "lucide-react";

interface Agendamento {
  id: number;
  dataHora: string;
  volume: string;
  nfEntrada: string;
  transportadora: string;
  especie: "Caixa" | "Pallet";
  tipoOperacao: "Armazenagem" | "CrossDocking";
  xmlFile: string;
}

export default function RecebimentoPage(): JSX.Element {
  const [agendamentos, setAgendamentos] = useState<Agendamento[]>([]);
  const [dataHora, setDataHora] = useState<string>("");
  const [volume, setVolume] = useState<string>("");
  const [nfEntrada, setNfEntrada] = useState<string>("");
  const [transportadora, setTransportadora] = useState<string>("");
  const [especie, setEspecie] = useState<"Caixa" | "Pallet">("Caixa");
  const [tipoOperacao, setTipoOperacao] = useState<
    "Armazenagem" | "CrossDocking"
  >("Armazenagem");
  const [xmlFile, setXmlFile] = useState<File | null>(null);
  const [dialogVisible, setDialogVisible] = useState<boolean>(false);
  const [selectedAgendamento, setSelectedAgendamento] =
    useState<Agendamento | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);

  useEffect(() => {
    const dadosSimulados: Agendamento[] = [
      {
        id: 1,
        dataHora: "2023-05-20T10:00",
        volume: "100kg",
        nfEntrada: "NF001",
        transportadora: "TransLog",
        especie: "Pallet",
        tipoOperacao: "Armazenagem",
        xmlFile: "nf001.xml",
      },
      {
        id: 2,
        dataHora: "2023-05-21T14:30",
        volume: "200kg",
        nfEntrada: "NF002",
        especie: "Caixa",
        transportadora: "FastTruck",
        tipoOperacao: "CrossDocking",
        xmlFile: "nf002.xml",
      },
    ];
    setAgendamentos(dadosSimulados);
  }, []);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && file.type === "text/xml") {
      setXmlFile(file);
      showSnackbar("Arquivo XML carregado com sucesso!");
    } else {
      showSnackbar("Por favor, selecione um arquivo XML válido.", "error");
    }
  };

  const criarAgendamento = async () => {
    if (
      !dataHora ||
      !volume ||
      !nfEntrada ||
      !transportadora ||
      !especie ||
      !tipoOperacao
    ) {
      showSnackbar(
        "Por favor, preencha todos os campos obrigatórios.",
        "error"
      );
      return;
    }

    const novoAgendamento: Agendamento = {
      id: Date.now(),
      dataHora,
      volume,
      nfEntrada,
      especie,
      transportadora,
      tipoOperacao,
      xmlFile: xmlFile ? xmlFile.name : "Nenhum arquivo",
    };

    try {
      const response = await fetch("caixa/armazem", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(novoAgendamento),
      });

      if (!response.ok) {
        throw new Error("Falha ao enviar agendamento");
      }

      setAgendamentos([...agendamentos, novoAgendamento]);
      limparFormulario();
      showSnackbar("Agendamento criado com sucesso!");
    } catch (error: any) {
      showSnackbar("Erro ao criar agendamento: " + error.message, "error");
    }
  };

  const limparFormulario = () => {
    setDataHora("");
    setVolume("");
    setNfEntrada("");
    setTransportadora("");
    setEspecie("Caixa");
    setTipoOperacao("Armazenagem");
    setXmlFile(null);
  };

  const abrirDetalhesAgendamento = (agendamento: Agendamento) => {
    setSelectedAgendamento(agendamento);
    setDialogVisible(true);
  };

  const excluirAgendamento = (id: number) => {
    setAgendamentos(agendamentos.filter((a) => a.id !== id));
    showSnackbar("Agendamento excluído com sucesso!");
  };

  const showSnackbar = (
    message: string,
    severity: "success" | "error" = "success"
  ) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="container mx-auto p-4 bg-gray-100 min-h-screen pt-16">
      <Card elevation={3} className="mb-6">
        <CardContent>
          <Typography variant="h4" className="text-center mb-6 text-blue-600">
            Agendamento de Recebimentos
          </Typography>

          <Paper elevation={3} className="p-6 mb-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
              <TextField
                label="Data e hora prevista"
                type="datetime-local"
                value={dataHora}
                onChange={(e) => setDataHora(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <Calendar className="mr-2 text-blue-500" size={20} />
                  ),
                }}
              />

              <TextField
                label="Volume de carga"
                value={volume}
                onChange={(e) => setVolume(e.target.value)}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <Package className="mr-2 text-blue-500" size={20} />
                  ),
                }}
              />

              <FormControl fullWidth>
                <InputLabel id="especie-label">Espécie</InputLabel>
                <Select
                  labelId="especie-label"
                  value={especie}
                  onChange={(e) =>
                    setEspecie(e.target.value as "Caixa" | "Pallet")
                  }
                  startAdornment={
                    <Box className="mr-2 text-blue-500" size={20} />
                  }
                >
                  <MenuItem value="Caixa">Caixa</MenuItem>
                  <MenuItem value="Pallet">Pallet</MenuItem>
                </Select>
              </FormControl>

              <TextField
                label="NF de entrada"
                value={nfEntrada}
                onChange={(e) => setNfEntrada(e.target.value)}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <FileText className="mr-2 text-blue-500" size={20} />
                  ),
                }}
              />

              <TextField
                label="Transportadora"
                value={transportadora}
                onChange={(e) => setTransportadora(e.target.value)}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <Truck className="mr-2 text-blue-500" size={20} />
                  ),
                }}
              />

              <FormControl fullWidth>
                <InputLabel id="tipo-operacao-label">
                  Tipo de operação
                </InputLabel>
                <Select
                  labelId="tipo-operacao-label"
                  value={tipoOperacao}
                  onChange={(e) =>
                    setTipoOperacao(
                      e.target.value as "Armazenagem" | "CrossDocking"
                    )
                  }
                  startAdornment={
                    <ListIcon className="mr-2 text-blue-500" size={20} />
                  }
                >
                  <MenuItem value="Armazenagem">Armazenagem</MenuItem>
                  <MenuItem value="CrossDocking">CrossDocking</MenuItem>
                </Select>
              </FormControl>

              <div className="flex items-center">
                <input
                  type="file"
                  accept=".xml"
                  onChange={handleFileChange}
                  className="hidden"
                  id="xml-file-input"
                />
                <label htmlFor="xml-file-input">
                  <Button
                    variant="contained"
                    component="span"
                    className="mr-2 bg-blue-500 hover:bg-blue-600"
                    startIcon={<FileText size={20} />}
                  >
                    Selecionar XML
                  </Button>
                </label>
                {xmlFile && (
                  <Typography variant="body2">{xmlFile.name}</Typography>
                )}
              </div>
            </div>

            <Button
              variant="contained"
              color="primary"
              onClick={criarAgendamento}
              className="w-full bg-green-500 hover:bg-green-600"
              startIcon={<Plus size={20} />}
            >
              Criar Agendamento
            </Button>
          </Paper>

          <Typography variant="h5" className="mb-4 text-blue-600">
            Agendamentos Criados
          </Typography>

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Data e Hora</TableCell>
                  <TableCell>Volume</TableCell>
                  <TableCell>NF Entrada</TableCell>
                  <TableCell>Transportadora</TableCell>
                  <TableCell>Especie</TableCell>
                  <TableCell>Tipo de Operação</TableCell>
                  <TableCell>Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {agendamentos
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((agendamento) => (
                    <TableRow key={agendamento.id}>
                      <TableCell>{agendamento.id}</TableCell>
                      <TableCell>
                        {new Date(agendamento.dataHora).toLocaleString()}
                      </TableCell>
                      <TableCell>{agendamento.volume}</TableCell>
                      <TableCell>{agendamento.nfEntrada}</TableCell>
                      <TableCell>{agendamento.transportadora}</TableCell>
                      <TableCell>{agendamento.especie}</TableCell>
                      <TableCell>{agendamento.tipoOperacao}</TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() => abrirDetalhesAgendamento(agendamento)}
                          color="primary"
                        >
                          <Eye size={20} />
                        </IconButton>
                        <IconButton
                          onClick={() => excluirAgendamento(agendamento.id)}
                          color="error"
                        >
                          <Trash2 size={20} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={agendamentos.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </CardContent>
      </Card>
      <Dialog
        open={dialogVisible}
        onClose={() => setDialogVisible(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Detalhes do Agendamento</DialogTitle>
        <DialogContent>
          {selectedAgendamento && (
            <div className="grid grid-cols-2 gap-4">
              <Typography>
                <strong>ID:</strong> {selectedAgendamento.id}
              </Typography>
              <Typography>
                <strong>Data e Hora:</strong>{" "}
                {new Date(selectedAgendamento.dataHora).toLocaleString()}
              </Typography>
              <Typography>
                <strong>Volume:</strong> {selectedAgendamento.volume}
              </Typography>
              <Typography>
                <strong>NF Entrada:</strong> {selectedAgendamento.nfEntrada}
              </Typography>
              <Typography>
                <strong>Espécie:</strong> {selectedAgendamento.especie}
              </Typography>
              <Typography>
                <strong>Transportadora:</strong>{" "}
                {selectedAgendamento.transportadora}
              </Typography>
              <Typography>
                <strong>Tipo de Operação:</strong>{" "}
                {selectedAgendamento.tipoOperacao}
              </Typography>
              <Typography>
                <strong>Arquivo XML:</strong> {selectedAgendamento.xmlFile}
              </Typography>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogVisible(false)} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
      <Fab
        color="primary"
        aria-label="add"
        className="fixed right-4 bottom-4 bg-blue-500 hover:bg-blue-600"
        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
      >
        <Plus size={24} />
      </Fab>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setSnackbarOpen(false)}
          >
            <X size={20} />
          </IconButton>
        }
      />
    </div>
  );
}
