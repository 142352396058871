import React, { useState } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ReceiptIcon from "@mui/icons-material/Receipt";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Menu from "@mui/material/Menu";
import ListAltIcon from "@mui/icons-material/ListAlt";
import AssessmentIcon from "@mui/icons-material/Assessment";
import MenuItem from "@mui/material/MenuItem";
import { Link, useNavigate } from "react-router-dom";

const drawerWidth = 240;

const MiniDrawer = () => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleCloseAndNavigate = () => {
    setOpen(false);
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem("access_token");
    handleClose();
    navigate("/login");
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="toggle drawer"
            edge="start"
            onClick={toggleDrawer}
            sx={{ marginRight: 2 }}
          >
            <MenuIcon sx={{ fontSize: 30 }} />{" "}
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            Cliente: UNIKA LTDA
          </Typography>
          <div>
            <IconButton
              color="inherit"
              aria-label="user account"
              onClick={handleMenu}
            >
              <AccountCircle sx={{ fontSize: 30 }} />{" "}
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="temporary"
        anchor="left"
        open={open}
        onClose={toggleDrawer}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          zIndex: 1300,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
            top: 64,
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            padding: "0 8px",
            justifyContent: "flex-end",
          }}
        >
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon sx={{ fontSize: 30 }} />{" "}
          </IconButton>
        </Box>
        <Divider />
        <List>
          <ListItem
            button
            component={Link}
            to="/home"
            onClick={handleCloseAndNavigate}
          >
            <ListItemIcon>
              <AssessmentIcon sx={{ fontSize: 28 }} />{" "}
            </ListItemIcon>
            {open && (
              <ListItemText primary="Relatório" sx={{ fontSize: "1.1rem" }} />
            )}{" "}
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/recebimento"
            onClick={handleCloseAndNavigate}
          >
            <ListItemIcon>
              <AssessmentIcon sx={{ fontSize: 28 }} />{" "}
            </ListItemIcon>
            {open && (
              <ListItemText
                primary="Agendar Recebimento"
                sx={{ fontSize: "1.1rem" }}
              />
            )}{" "}
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/produtos"
            onClick={handleCloseAndNavigate}
          >
            <ListItemIcon>
              <ReceiptIcon sx={{ fontSize: 28 }} />{" "}
            </ListItemIcon>
            {open && (
              <ListItemText
                primary="Recebimentos"
                sx={{ fontSize: "1.1rem" }}
              />
            )}{" "}
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/estoque"
            onClick={handleCloseAndNavigate}
          >
            <ListItemIcon>
              <Inventory2Icon sx={{ fontSize: 28 }} />{" "}
            </ListItemIcon>
            {open && (
              <ListItemText primary="Estoque" sx={{ fontSize: "1.1rem" }} />
            )}{" "}
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/pedidos"
            onClick={handleCloseAndNavigate}
          >
            <ListItemIcon>
              <ListAltIcon sx={{ fontSize: 28 }} />{" "}
            </ListItemIcon>
            {open && (
              <ListItemText primary="Pedidos" sx={{ fontSize: "1.1rem" }} />
            )}{" "}
          </ListItem>
        </List>
      </Drawer>
      {open && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.1)",
            backdropFilter: "blur(1px)",
            zIndex: 1200,
            transition: "all 0.3s ease",
          }}
          onClick={toggleDrawer}
        />
      )}
    </Box>
  );
};

export default MiniDrawer;
