import React, { useState } from "react";
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Button,
  Modal,
  TextField,
  Divider,
  Fab,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { DataRow, HistoryRow } from "../../types/ProdutosTypes";
import api from "../../api/axiosConfig";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";

type RowProps = {
  row: DataRow;
  onSelect: (descricao: string) => void;
};

type CollapsibleTableProps = {
  data: DataRow[];
  columns: {
    id: string;
    label: string;
    align?: "left" | "right" | "center";
  }[];
};

function Row({ row }: RowProps) {
  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedHistoryRows] = useState<HistoryRow[]>([]);
  const [quantities, setQuantities] = useState<{ [key: string]: number }>({});

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleQuantityChange = (cProd: string, value: string) => {
    setQuantities((prev) => ({
      ...prev,
      [cProd]: parseInt(value, 10) || 0,
    }));
  };

  const nfeId = row.history[0]?.nfeId;

  const handleSendOrder = async () => {
    try {
      const payload = selectedHistoryRows.map((selectedRow) => {
        return {
          referecinaPedido: "",
          referecinaCliente: "",
          trasportadora: "",
          detId: selectedRow.id,
          quantidade: quantities[selectedRow.cProd] || 1,
          descricao: selectedRow.xProd,
          cnpj: row.cnpj,
          empresaId: Number(1),
          nfeId: nfeId,
        };
      });
      console.log("payload", payload);
      await api.post("/cliente-pedido", payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      console.log("Pedido enviado com sucesso:", payload);
      handleCloseModal();
    } catch (error) {
      console.error("Erro ao enviar o pedido", error);
    }
  };

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="right">{row.nfe}</TableCell>
        <TableCell component="th" scope="row">
          {row.empresa}
        </TableCell>
        <TableCell align="right">{row.cnpj}</TableCell>
        <TableCell align="right">{row.dhEmi}</TableCell>
        <TableCell align="right">{row.quantidade}</TableCell>
        <TableCell align="center">
          <Modal
            open={modalOpen}
            onClose={handleCloseModal}
            aria-labelledby="pedido-modal-title"
            aria-describedby="pedido-modal-description"
          >
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[700px] max-h-[500px] bg-white border-2 border-gray-300 shadow-lg p-8 rounded-lg overflow-y-auto">
              <Typography id="pedido-modal-title" variant="h6" component="h2">
                Geração do Pedido/Solicitação de Retirada
              </Typography>
              {selectedHistoryRows.length > 0 ? (
                selectedHistoryRows.map((selectedRow, index) => (
                  <React.Fragment key={selectedRow.id}>
                    <Box mt={3}>
                      <Box display="flex" justifyContent="space-between" mb={2}>
                        <Typography variant="body1">
                          <strong>Código:</strong> {selectedRow.cProd}
                        </Typography>
                        <Typography variant="body1">
                          <strong>Data de Vencimento:</strong>{" "}
                          {new Date(selectedRow.validade).toLocaleDateString()}
                        </Typography>
                      </Box>
                      <Box mb={2}>
                        <Typography variant="body1">
                          <strong>Descrição:</strong> {selectedRow.xProd}
                        </Typography>
                      </Box>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        mb={2}
                      >
                        <Typography variant="body1">
                          <strong>Quantidade Disponível:</strong>{" "}
                          {selectedRow.qCom}
                        </Typography>
                        <TextField
                          label="Quantidade"
                          variant="outlined"
                          size="small"
                          style={{ width: "100px" }}
                          value={quantities[selectedRow.cProd] || ""}
                          onChange={(e) =>
                            handleQuantityChange(
                              selectedRow.cProd,
                              e.target.value
                            )
                          }
                        />
                      </Box>
                    </Box>
                    {index < selectedHistoryRows.length - 1 && <Divider />}
                  </React.Fragment>
                ))
              ) : (
                <Typography variant="body1">
                  Nenhum item selecionado.
                </Typography>
              )}
              <Button
                className="mt-[20px] w-50"
                variant="contained"
                onClick={handleSendOrder}
              >
                Enviar
              </Button>
            </div>
          </Modal>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Produtos
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox"></TableCell>
                    <TableCell align="right">Código</TableCell>
                    <TableCell>Descrição</TableCell>
                    <TableCell align="right">Quantidade</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.isArray(row.history) &&
                    row.history.map((historyRow) => (
                      <TableRow key={historyRow.cProd}>
                        <TableCell align="right">{historyRow.cProd}</TableCell>
                        <TableCell>{historyRow.xProd}</TableCell>
                        <TableCell align="right">{historyRow.qCom}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function CollapsibleTable({
  data,
  columns,
}: CollapsibleTableProps) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleFloatingButtonClick = () => {
    setIsLoading(true);
    setTimeout(() => {
      navigate({ pathname: "/recebimento" });
    }, 2000);
  };

  return (
    <Box sx={{ position: "relative", minHeight: "100vh" }}>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align || "left"}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <Row key={row.id} row={row} onSelect={() => {}} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Tooltip title="Adicionar novo recebimento" arrow>
        <Fab
          color="primary"
          aria-label="add"
          sx={{
            position: "fixed",
            bottom: 16,
            right: 16,
          }}
          onClick={handleFloatingButtonClick}
        >
          <AddIcon />
        </Fab>
      </Tooltip>
      <Modal
        open={isLoading}
        aria-labelledby="loading-modal-title"
        aria-describedby="loading-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            textAlign: "center",
          }}
        >
          <Typography
            id="loading-modal-title"
            variant="h6"
            component="h2"
            gutterBottom
          >
            Redirecionando
          </Typography>
          <CircularProgress sx={{ mb: 2 }} />
          <Typography id="loading-modal-description">
            Você será redirecionado para a página de novo recebimento em
            breve...
          </Typography>
        </Box>
      </Modal>
    </Box>
  );
}
