import React, { useContext } from "react";
import { DrawerContext } from "../../contexts/DrawerContext";
import { Box, Toolbar, Typography } from "@mui/material";
import CollapsibleTable from "../../component/Table/TableProdutos";
import { DataRow } from "../../types/ProdutosTypes";
import api from "../../api/axiosConfig";

const drawerWidth = 240;

const columns = [
  { id: "nfe", label: "Nota Fiscal", align: "right" as "right" },
  { id: "empresa", label: "Razão Social", align: "left" as "left" },
  { id: "cnpj", label: "CNPJ", align: "right" as "right" },
  { id: "dhEmi", label: "Data de Emissão", align: "right" as "right" },
  { id: "createAt", label: "Data Recebida", align: "right" as "right" },
];

const ProdutosPage: React.FC = () => {
  const drawerContext = useContext(DrawerContext);
  const [history, setHistory] = React.useState<DataRow[]>([]);
  console.log("history", history);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get("/recebimentos-entradas");
        const mappedData = response.data.map((item: any) => {
          return {
            empresa: item.Empresa
              ? item.Empresa.razao_social
              : "Empresa não informada",
            dhEmi: new Date(item.dhEmi).toLocaleString("pt-BR"),
            quantidade: item.quantidade,
            nfe: item.nNF,
            cnpj: item.Empresa ? item.Empresa.cnpj : "CNPJ não informado",
            history: Array.isArray(item.det)
              ? item.det.map((detItem: any) => ({
                  ...detItem,
                  validade: detItem.validade,
                }))
              : [],
          };
        });

        console.log("retorno do:", mappedData);
        setHistory(mappedData);
      } catch (error) {
        console.error("Erro ao buscar os detalhes do produto", error);
      }
    };

    fetchData();
  }, []);

  if (!drawerContext) {
    throw new Error("DrawerContext must be used within a DrawerProvider");
  }

  const { open } = drawerContext;

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 5,
        p: 5,
        transition: "margin 0.3s ease",
        marginLeft: open ? `${drawerWidth}px` : `0px`,
      }}
    >
      <Toolbar />
      <Typography paragraph sx={{ fontWeight: "bold", fontSize: "2.15rem" }}>
        Recebimentos
      </Typography>
      <CollapsibleTable data={history} columns={columns} />
    </Box>
  );
};

export default ProdutosPage;
